<template>
  <div class="login_content">
    <div class="box">
      <!-- 重置密码 -->
      <el-card class="box_card_register">
        <h1>重置密码</h1>
        <!-- 表单注册 -->
        <el-form ref="regFormRef" :model="RegForm" label-width="80px">
          <!-- 手机号 -->
          <!-- <el-form-item label="手机号:">
            <el-input v-model="RegForm.userName"></el-input>
          </el-form-item> -->
          <!-- 密码 -->
          <el-form-item label="密码:">
            <el-input v-model="RegForm.passWord"></el-input>
          </el-form-item>
        </el-form>
        <!-- 注册按钮 -->
        <div class="login_btn">
          <el-button type="primary" @click="change" round>重置密码</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      RegForm: {
        // userName: '',
        passWord: ''
      }
    }
  },
  methods: {
    async change () {
      await this.$http.post('updated_password', { password: this.RegForm.passWord })
      this.$message({ message: '重置密码成功', type: 'success' })
      this.$router.push('/login')
      window.sessionStorage.removeItem('token')
    }
  }
}
</script>

<style lang="less" scoped>
.login_content{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #324057;
  .box{
    width: 30%;
    .box_card,.box_card_register{
      h1{
        text-align: center;
        font-size: 2rem;
        padding: 1rem 0;
      }
      .login_btn{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .el-button{
          width: 50%;
          border-radius: 25px;
          padding: 1rem 0;
          margin: 0.5rem 0;
        }
      }
    }
  }
}
</style>
